<template>
  <div>
    <form
      id="nowwRedirectForm"
      method="POST"
      :action="nowwLoginPage"
    >
      <input
        type="hidden"
        name="token"
        :value="getToken()"
      >
      <input
        type="hidden"
        name="refreshToken"
        :value="getRefreshToken()"
      >
      <input
        type="hidden"
        name="dest"
        :value="fullNowwRoute"
      >
      <input
        type="hidden"
        name="selectedCustomerNowwId"
        :value="simulatedCustomerNowwId()"
      >
      <input
        type="hidden"
        name="selectedCustomerIsContracted"
        :value="simulatedCustomerIsContracted()"
      >
      <input
        type="hidden"
        name="selectedContactNowwId"
        :value="nowwTransisitonUserNowwId"
      >
      <input
        type="hidden"
        name="csAgent"
        :value="getFullName()"
      >

      <p v-if="showDebug">
        {{ fullNowwRoute }}
      </p>
    </form>
  </div>
</template>

<script>
import { SET_LOADING_MESSAGE, OPEN_HTTP_REQ } from '@/store/mutation-types'
import { mapMutations, mapGetters } from 'vuex'
import axios from 'axios' // or maybe use config'd axios (client, @/shared/axios-config.ts)
import { NOWWUrl } from '@/shared/axios-config'

export default {
  name: 'NowwRedirect',

  props: {
    nowwRoute: {
      type: String,
      default: 'dashboard2.aspx'
    }
  },

  data () {
    return {
      showDebug: process.env.VUE_APP_IS_DEBUG === 'true',
      nowwLoginPage: `${NOWWUrl}/newLogin.aspx`
    }
  },

  computed: {
    ...mapGetters('auth', ['getToken', 'getRefreshToken', 'getFullName', 'getUserNowwId']),
    ...mapGetters(['simulatedCustomerNowwId', 'filterUserNowwId', 'simulatedCustomerIsContracted']),
    fullNowwRoute () {
      let query = []
      if (this.$route.query) {
        for (const prop in this.$route.query) {
          query.push(`${prop}=${this.$route.query[prop]}`)
        }
      }
      return `${this.nowwRoute}${
        query.length > 0 ? '?' + query.join('&') : ''
      }`
    },
    nowwTransisitonUserNowwId () {
      return this.filterUserNowwId() === 0 || this.filterUserNowwId() === null ? this.getUserNowwId() : this.filterUserNowwId()
    }

  },

  mounted () {
    this.setLoadingMessage('random')
    this.openHttpReq()
    document.getElementById('nowwRedirectForm').submit()
  },

  methods: {
    ...mapMutations([SET_LOADING_MESSAGE, OPEN_HTTP_REQ])
  }

}
</script>
